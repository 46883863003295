<template>

</template>

<script>
export default {
  methods:{
    checkUserRole(){
      var userRole = JSON.parse(localStorage.getItem('userData')).role
      if (userRole == ('partner-individual')) {
        this.$router.push({name: 'home-partner-individual'})
      }else if(userRole == ('partner-company')){
        var status = JSON.parse(localStorage.getItem('userData')).inviteStatus
         if (!status) {
          this.$router.push({name: 'home-partner-company'})
        }else{
          if (status == "pending") {
            this.$router.push({name: 'application-pending-partner'})
          }else{
            this.$router.push({name: 'company-partner'})
          }
        }
        
      }
        else{
            this.$router.push({name: 'dashboard-ecommerce'})
        }
    },
  },
  mounted(){
    this.checkUserRole()
  },
}
</script>